import { useState, useContext, useEffect } from "react"
import { Link, useParams } from "react-router-dom";
import { UserContext } from "context/userContext";
import { QuesContext } from "context/QuesContext";
import { Button, Card, Col, Container, Form, Input, Label, Row } from "reactstrap";
import { AlertMsgContext } from "context/AlertMsgContext";
import { LightbulbFill, LockFill, PencilFill, PlusCircleFill } from "react-bootstrap-icons";
import LoadingPage from "./Partials/Loading";
import AddQueToUser from "./Partials/AddQueToUser";


const UserPage = () => {

    document.documentElement.classList.remove("nav-open");
    useEffect(() => {
        document.body.classList.add("register-page");
        document.body.classList.add("full-screen");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("register-page");
            document.body.classList.remove("full-screen");
        };
    });

    const { id } = useParams()
    const { currentUser, isAdmin, dataUrl, token } = useContext(UserContext)
    const { ques: currentUserQues } = useContext(QuesContext)
    const { showAlert } = useContext(AlertMsgContext)
    
    const [isLoading, setIsLoading] = useState(true)
    const [isAuthorized, setIsAuthorized] = useState(false)
    const [isAdminWatchingUser, setIsAdminWatchingUser] = useState(false)

    const [user, setUser] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [userName, setUserName] = useState(null)
    const [ques, setQues] = useState(null)
    const [showAddQue, setShowAddQue] = useState(false)

    let headers = new Headers()
    headers.set('Accept', 'application/json')
    const options = {credentials: 'include', mode: 'cors', headers}

    useEffect(async()=>{
        if (currentUser){
            if (currentUser.ID == id) {
                setUser(currentUser)
                openTab(null, 'details-tab')
                setIsAdminWatchingUser(false)
            }
            else if (isAdmin) {
                setIsAdminWatchingUser(true)
                headers.set('Authorization', 'Bearer ' + token);
                await fetch(dataUrl+'users/'+id, options)
                .then(res=>res.json())
                .then(res=>{
                    if (res.success) return res.data
                    else throw new Error(res.message)
                })
                .then(data=>setUser(data))
                .then(()=>
                    fetch(dataUrl+'quesnaires/user/'+id, {headers} ))
                .then(res=>res.json())
                .then(res=>setQues(res.data))
                .catch(err=>console.log(err)) 
            }
            setIsLoading(false)
        }
    },[id, currentUserQues])

    useEffect(()=>{
        if (user){
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setUserName(user.userName)
            setIsAuthorized(true)
        }
    }, [user])

    const openTab = (evt, tabName) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-content");
        for (i = 0; i < tabcontent?.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tab-buttons")[0]?.children;
        for (i = 0; i < tablinks?.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        let thisTab = document.getElementById(tabName)
        if (thisTab) thisTab.style.display = "block"
        if (evt) evt.currentTarget.className += " active"
    }

    const changeDetails = (e) => {
        e.preventDefault()
        if (firstName !== user.firstName || lastName !== user.lastName || userName !== user.userName)
            console.log('details changed')
        else showAlert('אין שינוי בפרטים שהזנת', 'warning')
    }

    return (
        isLoading? <LoadingPage /> :
        <div className="wrapper">
            <div className="page-header" style={{ backgroundImage: "url(" + require("assets/img/sections/section-header-3.jpg").default + ")" }}>
                <div className="filter" />
                {isAuthorized ?
                    <Container>
                        <Row>
                            <Col className="mx-auto text-center" lg="5">
                                <h1 className="px-2 mb-4">{user.displayName}</h1>
                                <Card className="mx-auto mt-3 card-user-page">
                                    {isAdminWatchingUser &&
                                        <div className="tab-buttons rounded-top">
                                            <button onClick={(e) => openTab(e, 'details-tab')} className="right">פרטים</button>
                                            <button onClick={(e) => openTab(e, 'ques-tab')}  className="left">שאלונים</button>
                                        </div>
                                    }
                                    <div className="tab-content" id="details-tab">
                                        <h3 className="m-0">שינוי פרטים</h3>
                                        <Form className="mb-3 mx-auto w-100 " onSubmit={(e) => changeDetails(e)}>
                                            <Label className="w-100 text-right px-2 mb-0" >שם פרטי</Label>
                                            <Input type="text" id="first-name"
                                                value={firstName}
                                                className='p-2'
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            <Label className="w-100 text-right px-2 mb-0" >שם משפחה</Label>
                                            <Input type="text" id="last-name"
                                                value={lastName}
                                                className='p-2'
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                            <Label className="w-100 text-right px-2 mb-0" >אימייל</Label>
                                            <Input type="text" id="email"
                                                value={userName}
                                                className='p-2 mb-4'
                                                onChange={(e) => setUserName(e.target.value)}
                                            />
                                            <Button block className="btn-round mb-3" color='purple' type="submit">
                                                שנה פרטים
                                            </Button>
                                            {isAdminWatchingUser &&
                                                <Button block className="btn-round" color='yellow'
                                                    onClick={() => window.open("https://ques.chanashtemer.co.il/wp-login.php?action=lostpassword", '_blank')}
                                                > שלח איפוס סיסמא
                                                </Button>
                                            }
                                        </Form>
                                    </div>

                                    <div className="tab-content" id="ques-tab">
                                        <h3 className="mb-4">שאלונים</h3>
                                        <div className="my-3">
                                            {ques?.length>0 ?
                                                ques.map(que => (
                                                    <Card tag={Link} key={que.title} to={`/questionnaires/${que.id}`} >
                                                        <div className="que-row">
                                                            <div>{que.title}</div>
                                                            {{
                                                                'open':
                                                                    <div className='text-success'> <PencilFill className='ml-2' /> פתוח </div>,
                                                                'lock':
                                                                    <div className='text-danger'> <LockFill className='ml-2' /> נעול </div>,
                                                                'edit':
                                                                    <div className='text-warning'> <PencilFill className='ml-2' /> במילוי </div>,
                                                                'waiting':
                                                                    <div className='text-purple'> <LightbulbFill className='ml-2' /> ממתין לפענוח </div>,
                                                                'finished':
                                                                    <div className='text-success'> <LockFill className='ml-2' /> הסתיים </div>
                                                            }[que.user_status]}
                                                        </div>
                                                    </Card>
                                                ))
                                                : <div>השאלונים בדרך...</div>
                                            }
                                        </div>
                                        <div title="הוספת שאלון ללקוח" className="text-right px-2">
                                            <PlusCircleFill className="icon-button text-gray-orange" tooltip="lalala" 
                                                onClick={()=>setShowAddQue(true)}
                                            />
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <AddQueToUser {...{showAddQue, setShowAddQue, user}} />
                    </Container>
                    :
                    <div className="content-center">
                        <h1 className="title">אין לך הרשאת גישה לדף זה</h1>
                    </div>
                }
            </div>
        </div>
    );
}

export default UserPage;