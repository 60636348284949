import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AlertMsgContextProvider from "context/AlertMsgContext";
import UserContextProvider from "context/userContext";
import QuesContexProvider from "context/QuesContext";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/custom-sh-ques/custom.min.css"
import "assets/css/custom-sh-ques/custom.css"
// pages
import App from 'components/App'

const tokenUrl = '/wp-json/jwt-auth/v1/token/'
const dataUrl  = '/wp-json/sh-ques/v1/'
let headers = new Headers()
headers.set('Accept', 'application/json'); 

ReactDOM.render(
  <BrowserRouter>
    <AlertMsgContextProvider>
      <UserContextProvider {...{tokenUrl, dataUrl, headers}} >
        <QuesContexProvider {...{dataUrl, headers}}>
          <App />
        </QuesContexProvider>
      </UserContextProvider>
    </AlertMsgContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
