import { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Progress } from "reactstrap";
import { QuesContext } from "context/QuesContext";
import LoadingPage from "./Partials/Loading";
import Question from "./Partials/Question";
import Analysis from "./Partials/Analysis";
import YellowNavbar from "./Partials/YellowNavbar";
import { UserContext } from "context/userContext";
import { AlertMsgContext } from "context/AlertMsgContext";

const QuePage = () => {

    const { queId } = useParams()
    const { ques, headers, dataUrl } = useContext(QuesContext)
    const { currentUser } = useContext(UserContext)
    const token = localStorage.getItem('sh-ques-jwt')

    const [isLoading, setIsLoading] = useState(true)
    const [authorized, setAuthorized] = useState(false)
    const [quesnaire, setQuesnaire] = useState({})

    const [questions, setQuestions] = useState(null)
    const [fourQuestions, setFourQuestions] = useState(null)
    const [q1OnPage, setQ1OnPage] = useState(0)
    const [answers, setAnswers] = useState([])

    const [progress, setProgress] = useState(0)
    const [progressDelta, setProgressDelta] = useState(0)
    const [analysis, setAnalysis] = useState(false)
    const [numFilled, setNumFilled] = useState(0)
    const { showAlert } = useContext(AlertMsgContext)

    useEffect(() => {
        const thequesnaire = ques?.filter(quesnaire => quesnaire.id === queId)[0]
        setQuesnaire(thequesnaire);
    }, [ques])

    useEffect(async () => {
        headers.set('Authorization', 'Bearer ' + token);
        await fetch(dataUrl + 'quesnaires/' + queId + '/questions', { headers })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    setQuestions(res.data);
                    setProgressDelta(100 / res.data.length);
                    setProgress(progressDelta * 4);
                } else throw new Error("You are not authorized to view this page.")
            })
            .then(() => fetch(dataUrl + 'quesnaires/' + queId + '/user/' + currentUser.ID + '/answers', { headers }))
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    setAnswers(res.data)
                } else throw new Error("You are not authorized to view this page.")
            })
            .catch(err => console.log(err))
        setIsLoading(false)
    }, [token])

    useEffect(() => {
        if (answers.length > 0) {
            questions?.map(question => {
                const answer = answers.filter(ans => ans.question === question.id)[0]?.answer;
                question.answer = answer ?? '';
            })
            setNumFilled(answers.filter(ans => !!ans.answer).length)
        }
        quesnaire.status === 'open' || setAuthorized(true);
    }, [answers])

    useEffect(() => {
        setFourQuestions(newFourQuestions());
        setProgress((q1OnPage * progressDelta) + (progressDelta * 4));
    }, [authorized, q1OnPage, progressDelta])

    function newFourQuestions() {
        return questions?.slice(q1OnPage, q1OnPage + 4)
    }

    function nextBtn() {
        const four = newFourQuestions();
        const notAnswer = four.filter(ans => !ans.answer);
        if (!notAnswer.length || notAnswer.length) {
            setQ1OnPage(q => q + 4 < questions.length ? q + 4 : q);
            if (q1OnPage + 4 === questions.length) {
                setAnalysis(true);
            }
        } else {
            showAlert('שאלת חובה', 'danger');
        }
    }

    /* useEffect(() => {
        setProgress((numFilled * progressDelta) + (progressDelta * 4))
    }, [numFilled]) */

    return (
        isLoading ? <LoadingPage /> :
            <div className="bg-purple"
            >
                <div className="" />
                {authorized ?
                    <>
                        {/* <YellowNavbar quesnaire={quesnaire} /> */}
                        <div className="p-5 m-5">
                            <h1 className="h1 text-light text-center">{quesnaire?.title}</h1>
                            <Container >
                                <Row className="justify-content-center">
                                    <Col lg="10" className="bg-white rounded text-dark p-5 m-5">
                                        {!analysis ?
                                            <>
                                                <Progress value={progress} color='info'></Progress>

                                                <div className="questions">
                                                    {fourQuestions?.map(question => (
                                                        <Question key={question.id} {...{ queId, question, setQuestions }} />
                                                    ))}
                                                </div>
                                                <div className="d-flex justify-content-between px-3 mx-4 mb-3">
                                                    <Button color='purple' className="rounded-5 p-3"
                                                        disabled={q1OnPage == 0}
                                                        onClick={() => setQ1OnPage(q => q > 0 ? q - 4 : 0)}>
                                                        הקודם
                                                    </Button>
                                                    <Button color={q1OnPage + 4 === questions.length ? 'danger' : 'purple'} className="rounded-5 p-3 px-4"
                                                        onClick={nextBtn}>
                                                        {q1OnPage + 4 === questions.length ? 'סיום' : 'הבא'}

                                                    </Button>
                                                </div>
                                            </>
                                            :
                                            <Analysis {...{ queId, questions, setQuestions }} />
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>

                    :
                    <div className="content-center">
                        <h1 className="title">אין לך הרשאת גישה לשאלון זה</h1>
                    </div>
                }
            </div>
    );
}

export default QuePage;