import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { useEffect, useContext } from "react";

import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import HomePage from "./Home";
import QuePage from "./QuePage";
import LoadingPage from "./Partials/Loading";
import UserPage from "./UserPage";
import NavbarComponent from "./Partials/Navbar";

import { UserContext } from "context/userContext";
import { AlertMsgContext } from "context/AlertMsgContext";
import AllUsersPage from "./AllUsersPage";
import PageNotFound from "./PageNotFound";
import QuesPreview from "./Partials/QuesPreview";


function App() {

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("App-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("App-page")
    }
  })

  const { isLoading, currentUser } = useContext(UserContext)
  const { alert } = useContext(AlertMsgContext)

  return (
    <BrowserRouter>
      { isLoading ?
        <LoadingPage />
         :
        <> 
          {alert()}
          <NavbarComponent />
          <Switch>
            <Route path="/register" render={props => <RegisterPage {...props} /> } />
            <Route path="/about" render={props => <AboutUs {...props} /> } />
            <Route path="/contact-us" render={props => <ContactUs {...props} /> } />
          { currentUser?
            <Switch>
              <Route path="/user/:id" render={props=> <UserPage {...props}/>} />
              <Route path="/questionnaires/:queId" render={props=> <QuePage {...props}/>} />
              <Route path="/all-users" render={props=> <AllUsersPage {...props}/>} />
              <Route exact path="/app" render={props => <QuesPreview {...props} /> } />
              <Route path="*" render={props => <Redirect to="/app" /> } />
            </Switch>
            : 
            <>
              <Route path="/app" render={props => <LoginPage {...props} /> } /> 
              <Redirect to="/app" />
            </>
          }
          </Switch>
          <div className="sh-footer text-center">
            <h6>כל הזכויות שמורות ל'חנה שטמר - פיתוח ארגוני וקידום עסקים' </h6>
            <h6>בניית אתרים sh-pro.co.il</h6>
          </div>
        </>
      }
    </BrowserRouter>
  );
}

export default App;
