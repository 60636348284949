import { useEffect } from "react";
import QuesPreview from "./Partials/QuesPreview";

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    // this function is used to make the right bullets list
    // (the bellow <nav id="cd-vertical-nav">)
    // active when changeing the section on scroll
    const updateView = () => {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document.getElementById("cd-vertical-nav")?.getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop - window.innerHeight / 2 <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", updateView);
    };
  });
  return (
    <>
      {/* <div className="page-header cd-section" id='home-header'
          style={{ backgroundImage: "url(" + require("assets/img/cover.jpg").default + ")"}}
      >
        <div className="filter" />
        <div className="content-center">
          <h1 className="text-center sh-title text-white">שאלונים</h1>
          <h3 className="text-center text-white">שאלונים</h3>
        </div>
      </div> */}
      <QuesPreview />
      
      {/* <nav id="cd-vertical-nav">
        <ul className="pr-5">
          <li>
            <a
              data-number="1"
              href="#home-header"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("home-header").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">ראשי</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#questionnaires"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("questionnaires").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">שאלונים</span>
            </a>
          </li>

        </ul>
      </nav> */}
    </>
  );
}

export default HomePage;
