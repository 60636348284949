import { createContext, useEffect, useState } from 'react';

export const UserContext = createContext();

const UserContextProvider = (props) => {

    const { tokenUrl, dataUrl, headers } = props
    const  [token, setToken] = useState(localStorage.getItem('sh-ques-jwt'));
    const [ isLoading, setIsLoading ] = useState(true);
    const [ currentUser, setCurrentUser ] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false)
    const options = {credentials: 'include', mode: 'cors', headers}

    useEffect(async ()=>{
        if(token){
            console.log('checking token...')
            headers.set('Authorization', 'Bearer ' + token);
            await fetch(tokenUrl+'validate', {...options, method: 'POST', body: JSON.stringify(token) })
                .then(res=>res.json())
                .then(res=>{
                    if (res.data.status == 200) {
                        console.log('token is valid')
                        return;
                    } else throw new Error('token is invalid')
                })
                .then(()=> fetch(dataUrl+'current-user', options))
                .then(res=>res.json())
                .then(res=>{
                    if (res.success) return res.data
                    else throw new Error("couldn't fetch current user")
                })
                .then(data=>setCurrentUser(data))
                .catch(err=>console.log(err)) 
        }
        setIsLoading(false)
    },[token])

    useEffect(()=>{
        if(currentUser) 
            setIsAdmin(currentUser.caps === 'administrator')
        else setIsAdmin(false)
    },[currentUser])

    return (
        <UserContext.Provider value={{ currentUser, isLoading, token, setToken, setCurrentUser, tokenUrl, dataUrl, headers, isAdmin }} >
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;