import { useEffect, useState, useContext } from "react";
import { Button, Card, CardTitle, Form, Input, Container, Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "context/userContext";
import { AlertMsgContext } from "context/AlertMsgContext";

function RegisterPage() {

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPasswd, setConfirmPasswd] = useState('');
  const [sendMAil, setSendMail] = useState(false);
  const { dataUrl, tokenUrl, setToken } = useContext(UserContext)
  const { showAlert } = useContext(AlertMsgContext)
  const [showAddQue, setShowAddQue] = useState(false)
  const history = useHistory()

  const regUrl = dataUrl + 'user'

  const loginUser = () => {

    const options = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: email, password: pass })
    }

    fetch(tokenUrl, options)
      .then(res => res.json())
      .then(res => {
        if (res.token) {
          showAlert('התחברת בהצלחה', 'success')
          console.log('login succeeded')
          localStorage.setItem('sh-ques-jwt', res.token)
          setToken(res.token);
          history.push('/');
        }
        else {
          showAlert(res.message, 'danger')
          throw new Error(res.code)
        }
      })
      .catch(err => {
        console.log(err, err.code)
      })
  }

  const registerUser = (e) => {
    e.preventDefault();
    if (pass.length < 4 || pass == 1234 || pass == '0000'){
      showAlert('סיסמה חלשה', 'danger');
      return;
    }
    if (pass != confirmPasswd){
      showAlert('סיסמאות לא תואמות', 'danger');
      return;
    }

    let formData = new FormData();
    formData.append('email', email);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('pass', pass);

    let headers = new Headers();
    headers.set('Accept', 'application/json');

    const options = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers,
      body: formData
    };

    fetch(regUrl, options)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          showAlert("נרשמת בהצלחה", 'success')
          //document.getElementById('register-user').classList.add('pop-down')
          loginUser();
        }
        else {
          showAlert(res.data, 'danger');
          throw new Error('connection error');
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <div className="wrapper">
        <div className="page-header"
          style={{ backgroundImage: "url(" + require("assets/img/sections/cover.jpg").default + ")" }}
        >
          <div className="filter" />
          <Container id='register-user'>
            <Row>
              <Col className="mx-auto mt-5" lg="6" md="6" sm="5" xs="12">
                <Card className="mx-auto mt-3 card-register">
                  <CardTitle tag="h3">הרשמה למערכת השאלונים</CardTitle>
                  <Form className="register-form mt-5" onSubmit={registerUser}>
                    <Input placeholder="שם פרטי" required type="text" id="first-name" value={firstName} className='p-2'
                      onChange={(e) => setFirstName(e.target.value)} />
                    <Input placeholder="שם משפחה" required type="text" id="last-name" value={lastName} className='p-2'
                      onChange={(e) => setLastName(e.target.value)} />
                    <Input placeholder="אימייל" required type="text" id="email" value={email} className='p-2'
                      onChange={(e) => setEmail(e.target.value)} />
                    <Input placeholder="סיסמה" required type="password" id="new-password" value={pass} className='p-2'
                      onChange={(e) => setPass(e.target.value)} />
                    <Input placeholder="אימות סיסמה" required type="password" id="ConfirmPasswd" value={confirmPasswd} className='p-2'
                      onChange={(e) => setConfirmPasswd(e.target.value)} />
                    <div className="text-right">
                      <input type="checkbox" id="send-mail" required checked={sendMAil} onChange={() => setSendMail(val => !val)} />
                      <label className="text-dark mr-2 form-check-label" htmlFor="send-mail">אני מאשר/ת את תנאי האתר</label>
                    </div>
                    <Button block className="btn-round btn-info" color="danger" type="submit">
                      הרשמה
                    </Button>
                  </Form>
                  <div className="mt-4 text-dark w-100 text-right">
                    יש לך חשבון?
                    <Link to="/" className="text-blue">
                      <Button block className="btn-round btn-block m-0 mt-1" color="info">
                        כניסה
                      </Button>
                    </Link>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
