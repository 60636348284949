import { useContext, useEffect, useState } from "react"
import { UserContext } from "context/userContext"
import { FormGroup } from "reactstrap"

const Question = ({ queId, question, setQuestions }) => {

  const { currentUser, dataUrl, token } = useContext(UserContext)
  const [rangeOptions, setRangeOptions] = useState([
    { description: 'לא נכון', value: 1 },
    { description: 'נכון לפעמים', value: 2 },
    { description: 'נכון', value: 3 },
    { description: 'נכון מאוד', value: 4 },
  ])
  const rangeOptionWidth = 100 / rangeOptions.length

  let [answer, setAnswer] = useState(question.answer);
  const [previousAnswer, setPreviousAnswer] = useState(question.answer);
  const answerUrl = dataUrl + 'quesnaires/' + queId + '/user/' + currentUser.ID + '/answer/' + question.id;

  /* useEffect(() => {
    if (answer && !isNaN(answer) && question.type === 'line_options') {
      const ans = rangeOptions.filter(option => option.value == answer)[0]
      setAnswer(ans?.value)
      console.log(ans?.value, question.question);
    }
  }, []) */

  let headers = new Headers()
  headers.set('Accept', 'application/json')
  headers.set('Authorization', 'Bearer ' + token)

  const options = {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers
  }

  const setRangeAnswer = (e) => {
    setAnswer(e.target.value);
    submitQuestion(e.target.value, false);
  }

  const submitQuestion = (value, radio) => {
    if (radio) {
      //const data = new FormData(e.closest('form'));
      //answer = data.get(e.name) ?? ''
      setAnswer(value)
    }
    if (value !== previousAnswer) {
      let formData = new FormData()
      formData.append('answer', value)
      fetch(answerUrl, { ...options, body: formData })
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            setPreviousAnswer(value)
            setQuestions(qs => {
              return qs.map(q => {
                if (q.id == question.id) return { ...q, answer: value }
                else return q
              })
            })
          }
          else setAnswer(previousAnswer)
        })
    }
  }

  return (
    <div className="question p-3 m-2">
      <div className="ml-0 mb-2 d-flex">
        <div className="m-0 pl-1 d-inline h5">{question.question}</div>
        {question.required == 1 && <div className="text-danger m-0">*</div>}
      </div>

      {{
        'text':
          <textarea className="form-control w-75 p-2 answer" rows="1"
            placeholder={question.description}
            value={answer} onChange={(e) => setAnswer(e.target.value)}
            onBlur={submitQuestion}
          />,

        'number':
          <input type="number" className="form-control p-0 pr-2 w-25 answer"
            min={question.min_value} max={question.max_value}
            value={answer} onChange={(e) => setAnswer(e.target.value)}
            onBlur={submitQuestion}
          />,

        'select':
          <select className="form-control w-25 answer"
            value={answer} onChange={(e) => setAnswer(e.target.value)}
            onBlur={submitQuestion}
          >
            <option defaultChecked value='' >בחר</option>
            {question.options?.map(option => (
              <option value={option.name} key={option.number}>{option.name}</option>
            ))}
          </select>,

        'radio':
          <form className="d-flex align-items-center answer" onBlur={(e) => submitQuestion(e.target, true)} >
            <input type="radio" name={`question-${question.id}`} value="כן" className="ml-1"
              checked={answer === 'כן'} onChange={() => setAnswer(val => val === 'כן' ? '' : 'כן')} />כן
            <input type="radio" name={`question-${question.id}`} value="לא" className="ml-1 mr-3"
              checked={answer === 'לא'} onChange={() => setAnswer(val => val === 'לא' ? '' : 'לא')} />לא
          </form>,

        'line_options':
          <form>
            <table>
              <tbody>
                <tr className="answer" >
                  {rangeOptions.map(option => (
                    <td key={option.value} style={{ width: `${rangeOptionWidth}%` }}>
                      {option.description}
                    </td>
                  ))}
                </tr>
                <tr className="range-line">
                  {rangeOptions.map(option => (
                    <td key={option.value}>
                      <input type="radio" name={`question-${question.id}`} value={option.value}
                        checked={answer == option.value} onChange={setRangeAnswer} />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </form>
      }[question.type]}

    </div>
  );
}

export default Question;