import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavLink,
  Col,
} from "reactstrap";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "context/userContext";

function NavbarComponent() {

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"))
    headroom.init()
  }, [])

  const { currentUser, setCurrentUser, isAdmin } = useContext(UserContext)
  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);

  const disconnectUser = () => {
    localStorage.removeItem('sh-ques-jwt')
    setCurrentUser(null)
  }

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className="fixed-top"
        expand="lg"
        id="navbar-main"
      >
        <Container className="d-flex flex-row-reverse">
          <button
            className="navbar-toggler"
            id="navigation"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setBodyClick(true);
              setCollapseOpen(true);
            }}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>

          <div className="row d-flex justify-content-between align-items-center w-100">
            <Col className="col-5 col-xl-4 navbar-translate text-right p-0">
              <NavbarBrand id="navbar-brand" to="/app" tag={Link} className='p-0 m-0'>
                <img className="sh-icon" src={require("assets/img/logo.png").default} />
              </NavbarBrand>
            </Col>
            <Col className="col-7 col-xl-8 p-0">
              <Collapse navbar isOpen={collapseOpen}>
                <Nav className="m-0 p-0 d-flex justify-content-end w-100" navbar>

                  <NavItem>
                    <NavLink onClick={() => window.open("https://chanashtemer.co.il/")} tag={Link} to=''>
                      דף הבית
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink to="/app/#questionnaires" tag={Link} >
                      שאלונים
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink onClick={() => window.open("https://chanashtemer.co.il/#contact")} tag={Link} to=''>
                      צור קשר
                    </NavLink>
                  </NavItem>

                  {!currentUser &&
                    <NavItem>
                      <NavLink to="/register" tag={Link} >
                        הצטרף
                      </NavLink>
                    </NavItem>
                  }

                  {isAdmin &&
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle className="mr-2" color="default" caret nav >
                        לקוחות
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-warning" right>
                        <DropdownItem tag={Link} to='/all-users' className="text-right px-3 py-1" >
                          כל הלקוחות
                        </DropdownItem>
                        <DropdownItem tag={Link} to='/register' className="text-right px-3 py-1" >
                          הוספת לקוח חדש
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }

                  {currentUser &&
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle className="mr-2" color="default" caret nav >
                        שלום {currentUser.firstName}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-warning" right>
                        {/* <DropdownItem tag={Link} to={`/user/${currentUser.ID}`} className="text-right px-3 py-1" >
                          הפרופיל שלי
                        </DropdownItem> */}
                        <DropdownItem onClick={() => disconnectUser()} className="text-right px-3 py-1" >
                          יציאה
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }

                </Nav>
              </Collapse>
            </Col>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
