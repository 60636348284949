import FadingBalls from "react-cssfx-loading/lib/FadingBalls";


function LoadingPage() {

  return (
    <div className="page-header bg-purple">
      <div className="content-center">
          <h1 className="title">טוען</h1>
          <FadingBalls className="m-auto" color="white" width="20px" height="20px" duration="0.7s" />
      </div>
    </div>
  );
}

export default LoadingPage;
