import { createContext, useEffect, useState, useContext } from 'react';
import { UserContext } from "context/userContext";


export const QuesContext = createContext()

const QuesContexProvider = (props) => {

  const { dataUrl, headers } = props
  const { token, currentUser } = useContext(UserContext)
  const [isLoadingQues, setIsLoadingQues] = useState(true)
  const [ques, setQues] = useState([])

  useEffect(async()=>{
    if(currentUser){
      headers.set('Authorization', 'Bearer ' + token);
      console.log('fetching ques...')
      await fetch( dataUrl+'quesnaires', {headers} )
        .then(res=>res.json())
        .then(res=>setQues(res.data))
        .catch(err=>console.log(err))
    }
    setIsLoadingQues(false)
  },[currentUser])

  return ( 
      <QuesContext.Provider   value = {{ isLoadingQues, ques, dataUrl, headers }}>
          {props.children}
      </QuesContext.Provider>

    );
}
 
export default QuesContexProvider;