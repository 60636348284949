import { useContext, useEffect, useState } from "react"
import { UserContext } from "context/userContext"
import { FormGroup, Progress } from "reactstrap"

const Question = ({ queId, questions, setQuestions }) => {

  let [summary, setSummary] = useState(getSummary());
  let [mishpat, setMishpat] = useState(getmishpat());
  let [view, setView] = useState(0);

  useEffect(() => {
    if (view < 2) {
      setTimeout(() => {
        setView(view => view + 1)
      }, 4000);
    }
  }, [view])

  useEffect(() => {
    setMishpat(getmishpat());
  }, [summary])

  function getSummary() {
    let sum = questions.reduce((accumulator, object) => {
      let ans = object.answer * 1;
      if (object.category == 11) {
        switch (object.answer) {
          case '1':
            ans = 4;
            break;
          case '2':
            ans = 3;
            break;
          case '3':
            ans = 2;
            break;
          case '4':
            ans = 1
            break;
        }
      };

      return accumulator + (ans);
    }, 0);

    const total = questions.length * 4;
    const onePrecent = 100 / total
    let precent = Math.round(sum * onePrecent);
    if (precent > 88) precent = 88;
    if (precent < 25) precent = 25;

    return { precent: precent, sum: sum };
  }

  function getmishpat() {
    const summy = summary.sum;
    if (summy < 85) {
      return 'ייתכן שהגיע הזמן לחשוב על שינוי, כדי שאכן תוכל לממש את הפוטציאל שלך!!'
    }
    else if (summy < 115) {
      return 'נראה שאתה בכיוון טוב, אם כי אתה לא מממש מספיק את הפוטנציאל שלך. חבל. מומלץ לך לבדוק מה ניתן לעשות בנידון'
    }
    else if (summy < 146) {
      return 'אתה מממש יפה את הפוטנציאל שלך, עם זאת, מומלץ לך לבדוק איך אתה מתקדם ומממש את הפוטנציאל באופן מלא.'
    }
    else {
      return 'כל הכבוד! העסק שלך מממש את הפוטנציאל שלך! המשך להשקיע בו!'
    }


  }

  return (
    <div className="text-center p-5 m-2">
      {view == 0 &&
        <div className="">
          <img className="rotate-hor-center" src={require("assets/img/icon.png").default}
            style={{ width: "120px" }}
          />
          <h2 className="p-5">בודקת את הנתונים...</h2>
        </div>
      }
      {view == 1 &&
        <div className="">
          <img className=" rotate-center" src={require("assets/img/icon.png").default}
            style={{ width: "120px" }}
          />
          <h2 className="p-5">התוצאות בדרך...</h2>
        </div>
      }
      {view > 1 &&
        <div className="">
          <div className="summ m-auto">
            <div
              style={{ height: 100 - summary.precent + '%' }}
            ></div>
            <h4 className="p-2 text-light">{summary.precent}%</h4>
          </div>
          <h2 className="p-4"><b>{mishpat}</b></h2>
          <h4 className="p-4">
            נוכל לבדוק יחד, איך אני יכולה לעזור לך לממש את מלוא הפוטנציאל שלך.
            ממליצה לך למלא שאלון קצר,
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdlBiHOuOddo8XoWqVKhL8nQF4LqLvBkADHEUhpxWrscvpLcw/viewform?usp=sharing"
              target={'_blank'}
            > כאן בקישור
            </a>, ואצור איתך קשר בהקדם
          </h4>
          <img className=""
            style={{ width: "250px" }}
            src={require("assets/img/logo.png").default} />
        </div>
      }
    </div>
  );
}

export default Question;