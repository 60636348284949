import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardFooter, Button, CardImg, CardText } from 'reactstrap'
import { PencilFill, LockFill, LightbulbFill } from 'react-bootstrap-icons'
import { UserContext } from 'context/userContext'


const QueCard = ({ que }) => {

    const [linkProps, setLinkProps] = useState({ to: `/questionnaires/${que.id}` })
    const { isAdmin } = useContext(UserContext)


    const toggleButton = (e) => {
        e.preventDefault()
        alert('לרכישה צרו קשר');
        /* const button = document.getElementById("purchase-button-" + que.id)
        button.classList.toggle("d-none") */
    }

    useEffect(() => {
        if (!isAdmin && que.user_status === 'lock')
            setLinkProps({ onClick: toggleButton, to: "" })
        else
            setLinkProps({ to: `/questionnaires/${que.id}` })
    }, [que, isAdmin])

    return (
        <>
            <Card className=" mb-5">
                <Link {...linkProps} >
                    <CardImg className="card-image-radius" src={que.image || require("assets/img/sections/pavel-kosov.jpg").default} />
                <CardBody className='text-center'>
                    <CardTitle className='h5'>{que.title}</CardTitle>
                    <CardText className='mt-3'>{que.description}</CardText>
                    <hr />
                    {isAdmin ?
                        <CardFooter className='text-center'>
                            <div className='text-success'> פתוח: {que.open} </div>
                            <div className='text-warning'> במילוי: {que.edit} </div>
                            <div className='text-purple'> ממתין לפענוח: {que.waiting} </div>
                            <div className='text-success'> הסתיים: {que.finished} </div>
                        </CardFooter>
                        :
                        <CardFooter className='d-flex flex-row align-items-center justify-content-between' style={{ height: '30px' }}>
                            {{
                                'open':
                                    <>
                                        <div className='text-success'> <PencilFill className='ml-2' /> פתוח </div>
                                        <Button id={`purchase-button-${que.id}`} className='px-4' color="success" >
                                            לשאלון {" >"}
                                        </Button>
                                    </>
                                ,
                                'lock':
                                    <>
                                        <div className='text-danger'> <LockFill className='ml-2' /> נעול </div>
                                        <Button id={`purchase-button-${que.id}`} className='px-4' color="danger" >
                                            לרכישה
                                        </Button>
                                    </>,
                                'edit':
                                    <>
                                        <div className='text-warning'> <PencilFill className='ml-2' /> במילוי </div>
                                        <Button id={`purchase-button-${que.id}`} className='px-4' color="success" >
                                            לשאלון {" >"}
                                        </Button>
                                    </>,
                                'waiting':
                                    <>
                                        <div className='text-purple'> <LightbulbFill className='ml-2' /> ממתין לפענוח </div>
                                    </>,
                                'finished':
                                    <>
                                        <div className='text-success'> <LockFill className='ml-2' /> הסתיים </div>
                                    </>
                            }[que.user_status]}
                        </CardFooter>
                    }
                </CardBody>
                </Link>
            </Card>
        </>
    );
}

export default QueCard;