import { useEffect, useState, useContext } from "react";
import { UserContext } from "context/userContext";
import { Link } from "react-router-dom";
import { Button, Card, CardTitle, Form, Input, Container, Row, Col } from "reactstrap";
import { AlertMsgContext } from "context/AlertMsgContext";

function LoginPage() {

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { tokenUrl, setToken } = useContext(UserContext)
  const { showAlert } = useContext(AlertMsgContext)

  const options = {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: email, password })
  }

  const loginUser = (e) => {
    e.preventDefault()
    if (!email) document.getElementById('email').focus();
    else if (!password) document.getElementById('password').focus();
    else {
      fetch(tokenUrl, options)
        .then(res => res.json())
        .then(res => {
          if (res.token) {
            showAlert('התחברת בהצלחה', 'success')
            localStorage.setItem('sh-ques-jwt', res.token)
            setToken(res.token)
          }
          else {
            showAlert(res.message, 'danger')
            throw new Error(res.code)
          }
        })
        .catch(err => {
          console.log(err, err.code)
        })
    }
  }

  return (
    <>
      <div className="wrapper">
        <div className="page-header"
          style={{ backgroundImage: "url(" + require("assets/img/sections/cover.jpg").default + ")" }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto mt-5" lg="4" md="6" sm="6">
                <Card className="card-register mt-3">
                  <CardTitle tag="h3">כניסה למערכת השאלונים של 'חנה שטמר'</CardTitle>
                  <Form className="register-form mt-5" onSubmit={loginUser}>
                    <Input className="p-2 mb-3" id='email' placeholder="אימייל" type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input className="p-2 mb-4" id='password' placeholder="סיסמא" type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button block className="btn-round" color="danger" type="submit" >
                      כניסה
                    </Button>
                  </Form>
                  <div className="text-right mb-3">
                    <Button className="btn-link m-0 p-0" color="danger"
                      onClick={() => window.open("https://ques.chanashtemer.co.il/wp-login.php?action=lostpassword", '_blank')}>
                      שכחתי סיסמא
                    </Button>
                  </div>
                  <div className="m-auto text-dark w-100 text-right">
                    אין לך חשבון?
                    <Link to="/register" className="text-blue">
                      <Button block className="btn-round btn-block m-0 mt-1" color="info">
                        הרשמה
                      </Button>
                    </Link>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
