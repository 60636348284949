import { UserContext } from "context/userContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Container, Row } from "reactstrap";

const AllUsersPage = () => {

    const history = useHistory();
    const { dataUrl, token, headers } = useContext(UserContext);
    const options = { credentials: 'include', mode: 'cors', headers };
    const demodata = useMemo(() =>
        [
            {
                id: '',
                displayName: '',
                email: '',
                quesOpen: '',
                quesEdit: '',
                quesWaiting: ''
            },
        ], [])
    let [data, setData] = useState(demodata);


    useEffect(() => {
        headers.set('Authorization', 'Bearer ' + token);
        fetch(dataUrl + 'users', options)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    let data = res.data;
                    data = data.map(user => {
                        return {
                            ...user,
                            display_name: user.data.display_name,
                            user_email: user.data.user_email
                        }
                    })
                    setData(data);
                }
                else throw new Error("couldn't fetch users data")
            })
            .catch(err => console.log(err))
    }, [token])

    const columns = useMemo(() =>
        [{ Header: 'שם הלקוח', accessor: 'display_name' },
        { Header: 'אימייל', accessor: 'user_email' },
        { Header: 'שאלונים פתוחים', accessor: 'quesOpen' },
        { Header: 'שאלונים במילוי', accessor: 'quesEdit' },
/*         { Header: 'שאלונים ממתינים', accessor: 'quesWaiting' }
 */        ]
        , [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter
    } = useTable({ columns, data }, useGlobalFilter, useSortBy)

    return (
        <div className="bg-drak"
        >
            <div className="filter" />
            <div className="mt-5 pt-5">
                <Container className="">
                    <h1 className="text-center title">לקוחות</h1>

                    <Row className="justify-content-center pb-5 mb-5">
                        <div className="text-right">
                            <input className="rounded-5 px-3 my-2 text-center" placeholder="חיפוש"
                                value={state.globalFilter || ''} onChange={e => setGlobalFilter(e.target.value)} />
                        </div>
                        <table {...getTableProps()} className="nice-table text-right">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        <th className='px-2'>
                                                id
                                            </th>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className='px-2'>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted ? (column.isSortedDesc ? ' 🔼' : ' 🔽') : ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} >
                                {rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()} onClick={() => { history.push('/user/' + row.original.id) }}>
                                            <td className='px-2'>
                                                {row.id}
                                            </td>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()} className='px-2'>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default AllUsersPage;