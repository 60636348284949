import { useContext } from "react";
import {Container, Row, Col} from "reactstrap";
import QueCard from "./QueCard";
import { QuesContext } from "context/QuesContext";

function QuesPreview() {

  const { ques } = useContext(QuesContext)

  return (
    <>
      <div className="page-header cd-section" id="questionnaires"
        style={{background: "#712f54"}}
      >
        <div className="sh-section">
          <Container className="mt-5">
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h1 className="title text-center mb-5">שאלונים</h1>
                <br />
                <Row className="justify-content-center">             
                  {ques.length>0 ? 
                    ques.map(que=>(
                      <Col md="4" key={que.id}>
                        <QueCard que={que}/>
                      </Col>
                    ))
                    : <div>השאלונים בדרך...</div>}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default QuesPreview;
