import { AlertMsgContext } from "context/AlertMsgContext";
import { useContext, useEffect } from "react";
import { Button, Card, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";


const AddQueToUser = ({showAddQue, setShowAddQue, user}) => {

    const { showAlert } = useContext(AlertMsgContext)

    useEffect(()=>{
        const addQueCard = document.getElementById('add-que')
        if (showAddQue)
            addQueCard.classList.remove("d-none")
        else 
            addQueCard.classList.add('pop-down')
        return () => 
            addQueCard.classList.remove('pop-down')
    }, [showAddQue])
    
    document.addEventListener('animationend', e=>{
        if (e.animationName === 'popDown') {
          e.target.classList.add('d-none');
        }
    })

    const addQue = (e) => {
        e.preventDefault()
        const selectQue = document.getElementById('select-que')
        if (selectQue.value === 'no-choice')
            showAlert('יש לבחור שאלון להוספה', 'danger')
        else {
            showAlert('השאלון נוסף בהצלחה', 'success')
            selectQue.value = 'no-choice'
            setShowAddQue(false)
        }
    }

    return (
        <Container className="position-center pop-up d-none" id='add-que' >
            <Row>
                <Col className="mx-auto mt-5" lg="6" md="6" sm="5" xs="12">
                    <Card className="mx-auto mt-3 card-register box-shadow-gray-orange">
                        <CardTitle tag="h3">הוספת שאלון ללקוח</CardTitle>
                        <CardTitle tag="h3" className="pt-1">{user?.displayName}</CardTitle>
                        <Form onSubmit={addQue}>
                            <FormGroup className="my-5">
                                <Input id="select-que" name="select" type="select" >
                                    <option value='no-choice' defaultChecked>בחר שאלון</option>
                                    <option value="1">אחת</option>
                                    <option value="2">שתיים</option>
                                    <option value="3">שלוש</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="d-flex">
                                <Button block className="btn-round btn-info ml-2" type="submit">אישור</Button>
                                <Button block className="btn-round mr-2" onClick={()=>setShowAddQue(false)}>ביטול</Button>
                            </FormGroup>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
 
export default AddQueToUser;